<template>
  <div class="home-page pt-6 px-sm-3 px-md-5 px-lg-7">
    <v-container fill-height fluid>
      <!-- Поиск и кнопка "создать проект". Скрываем если поиск пустой и никаких проектов не пришло, потому что будет блок с кнопкой "создать проект" -->
      <v-row
        v-show="
          (searchQuery === '' && projectList && projectList.length) ||
          searchQuery !== ''
        "
      >
        <!-- поиск -->
        <v-col cols="12" sm="8" md="9" lg="10">
          <v-text-field v-model="search" label="Поиск"></v-text-field>
        </v-col>

        <!-- создать проект -->
        <v-col class="text-right" align-self="center" sm="4" md="3" lg="2">
          <v-btn
            v-show="
              (searchQuery === '' && projectList && projectList.length) ||
              searchQuery !== ''
            "
            elevation="2"
            @click="createNewProject()"
            color="orange lighten-1"
          >
            Создать проект
          </v-btn>
        </v-col>
      </v-row>

      <!-- список проектов -->
      <v-row>
        <!-- вкладка "мои проекты" -->
        <v-tabs-items v-model="tab" class="full-width">
          <v-tab-item>
            <v-col v-if="this.auth.currentUser === null">
              Необходимо войти в аккаунт
            </v-col>
            <!-- Loading -->
            <v-col v-else-if="isLoading && !loadNextPage" class="loading"
              >Загрузка...</v-col
            >
            <!-- Error -->
            <v-col v-else-if="error" class="error">Непредвиденная ошибка</v-col>
            <!-- Result -->
            <v-col v-else-if="projectList && projectList.length" class="result">
              <v-row style="padding-bottom: 50px">
                <v-col
                  v-for="(project, projectIndex) in projectList"
                  :key="projectIndex"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  xl="4"
                >
                  <router-link
                    class="project-link"
                    :to="{
                      name: 'project-maps',
                      params: { prjCodename: project.codename },
                    }"
                    :disabled="isEditName"
                    tag="button"
                  >
                    <!-- карточка проекта -->
                    <v-card
                      height="210px"
                      :loading="
                        deleteLoading && deleteCodename === project.codename
                      "
                      elevation="2"
                      class="pointer project"
                    >
                      <div
                        class="project__background"
                        v-if="project.background_img"
                        :style="{
                          'background-image':
                            'url(' + project.background_img + ')',
                        }"
                      ></div>
                      <!-- кнопка редактирования -->
                      <v-btn
                        class="project__delete-btn orange lighten-2"
                        icon
                        @click.stop="beforeDelete(project)"
                      >
                        <v-icon color="black">mdi-delete</v-icon>
                        <!-- <div class="icon">
                            <img class="icon-svg" src="@/assets/img/delete.svg" alt="">
                          </div> -->
                      </v-btn>
                      <v-btn
                        class="project__edit-btn orange lighten-2"
                        color=""
                        icon
                        @click="toggleEditName(projectIndex, project)"
                      >
                        <img src="@/assets/img/edit.svg" alt="" />
                      </v-btn>
                      <!-- название проекта -->
                      <v-card-title
                        :class="{ project__title: project.background_img }"
                        v-if="!editList[projectIndex]"
                      >
                        <span>
                          {{ project.name }}
                        </span>
                      </v-card-title>
                      <!-- редактирование названия -->
                      <v-card-title
                        class="project__title project__title-edit"
                        v-else
                      >
                        <v-text-field
                          :class="{
                            'edit-name-input-wite-text': project.background_img,
                          }"
                          :color="
                            project.background_img ? 'white' : 'black lighten-1'
                          "
                          label="новое название проекта"
                          persistent-hint
                          v-model="newPrjName"
                          :rules="prjNameRulesMix"
                          @keypress.enter="editProjectName(project)"
                          @keydown.esc="toggleEditName(projectIndex)"
                          :loading="loadingUpdateProject"
                          autofocus
                        ></v-text-field>
                        <v-btn-toggle>
                          <v-btn
                            color="orange"
                            small
                            @click.stop="editProjectName(project)"
                          >
                            <v-icon color="green">
                              mdi-checkbox-marked-circle
                            </v-icon>
                          </v-btn>
                          <v-btn
                            color="orange"
                            small
                            @click.stop="toggleEditName(projectIndex)"
                          >
                            <v-icon color="red"> mdi-cancel </v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </v-card-title>

                      <v-card-subtitle
                        class="project__create-date text-left"
                        :class="
                          project.background_img ? 'white--text' : 'black--text'
                        "
                        v-show="!editList[projectIndex]"
                      >
                        Создан: {{ dateFilter(project.create_at) }}
                      </v-card-subtitle>

                      <v-card-actions
                        v-show="!editList[projectIndex]"
                        class="mt-15 pt-7"
                      >
                        <v-btn color="orange lighten-1" text>
                          Посмотреть
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </router-link>
                </v-col>
              </v-row>
              <VueVisibilityTrigger @scrolledIn="getNextPage($event)" />
            </v-col>

            <!-- если нет проектов -->

            <v-col v-else-if="searchQuery === ''" class="project__create-new">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center" cols="12">
                    <h1 class="display-1 font-weight-thin mb-4">
                      Проектов еще нет, но вы можете создать первый прямо сейчас
                    </h1>
                    <v-btn @click="createNewProject()" color="orange lighten-1">
                      Создать проект
                      <v-icon color="black small">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col v-else class="no-result apollo"
              >По вашему результату ничего не найдено :(</v-col
            >
          </v-tab-item>

          <!-- вкладка "общие проекты" -->
          <v-tab-item>
            <v-col>пока в разработке</v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-container>

    <!-- подтверждаение удаления -->
    <v-dialog v-model="confirmDelete" max-width="500">
      <v-card :loading="deleteLoading">
        <v-card-title class="headline text-center">
          Вы уверены, что хотите удалить проект<br /><b v-if="this.prjToDelete"
            >"{{ this.prjToDelete.name }}"</b
          >
        </v-card-title>

        <v-card-text class="text-center">
          Удаление проекта является необратимым действием. Все созданные карты и
          настройки проекта будут утеряны!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="deleteProject">
            Да, удалить проект
          </v-btn>

          <v-btn color="gray darken-1" text @click="cancelDelete">
            Нет, не удалять проект
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateProjectVue
      :dialog="createProject"
      @close-dialog="createProject = false"
      @createProject="refetchProjectList"
    />
  </div>
</template>

<script>
import VueVisibilityTrigger from "vue-visibility-trigger";
import { getAuth } from "firebase/auth";

const CreateProjectVue = () => import("./CreateProjectVue.vue");
const getMoment = () => import("moment");
// import moment from 'moment'

export default {
  components: {
    CreateProjectVue,
    VueVisibilityTrigger,
  },
  name: "home",
  data() {
    return {
      auth: getAuth(),

      tab: 0,
      search: "",
      searchQuery: "",
      offset: 0,
      inputTimer: null,
      inputDelay: 1000, // ms
      createProject: false,
      projectList: [],
      error: null,
      isLoading: 0,
      loadingUpdateProject: false,
      editList: [],
      newPrjName: "",
      isEditName: false,
      deleteLoading: false,
      deleteCodename: "",
      loadNextPage: false,
      hasMoreProjects: true,
      confirmDelete: false,
      prjToDelete: null,
      moment: null,
    };
  },

  computed: {
    isGraphToken() {
      return this.$store.getters.IS_GRAPH_TOKEN;
    },
  },

  // filters: {
  //   dateFilter: (val) => {
  //     return moment(val).add(3, 'hour').format('DD MMM YYYY в hh:mm')
  //   }
  // },

  watch: {
    search(newVal) {
      // задержка на ввод пользователя
      if (this.inputTimer !== null) {
        clearTimeout(this.inputTimer);
      }
      this.inputTimer = setTimeout(() => {
        this.offset = 0;
        this.hasMoreProjects = true;
        this.searchQuery = newVal;
      }, this.inputDelay);
    },

    projectList: {
      // отслеживаем обновление списка проектов, для генерации списка флагов на активацию редактирования
      handler: async function (newVal) {
        this.editList.splice(0, this.editList.length);
        this.editList = newVal.map(() => {
          return false;
        });
        if (!this.moment) {
          this.moment = await getMoment();
          this.moment.locale("ru");
        }
      },
      deep: true,
    },
  },

  apollo: {
    projectList: {
      query: require("@/graphql/Projects.gql"),
      variables() {
        return {
          search: this.searchQuery,
          // offset: this.offset
        };
      },
      update(data) {
        return data.project;
      },
      skip() {
        // не запрашивать если не залогинен, логинется или не установлен графКЛ токен
        return !(this.auth.currentUser && this.isGraphToken);
      },
      loadingKey: "isLoading",
      error(err) {
        this.error = err;
        console.log(err);
      },
    },
  },

  methods: {
    dateFilter(val) {
      if (this.moment) {
        return this.moment
          .default(val)
          .add(3, "hour")
          .format("DD MMM YYYY в hh:mm");
      } else {
        return "";
      }
    },

    cancelDelete() {
      this.confirmDelete = false;
      this.prjToDelete = null;
    },

    beforeDelete(prj) {
      this.prjToDelete = prj;
      this.confirmDelete = true;
    },

    refetchProjectList() {
      this.$apollo.queries.projectList.refetch();
    },

    getNextPage(e) {
      if (e) {
        if (!this.hasMoreProjects) {
          return;
        }
        this.offset += 20;
        this.loadNextPage = true;
        this.$apollo.queries.projectList.fetchMore({
          variables: {
            offset: this.offset,
            search: this.searchQuery,
          },
          updateQuery: (previosResult, { fetchMoreResult }) => {
            this.loadNextPage = false;
            this.hasMoreProjects = fetchMoreResult.project.length === 20; // хреновая проверка. Получаем по 20. Если получили меньше 20 получется что больше нет результатов
            let newResult = {
              project: [...previosResult.project, ...fetchMoreResult.project],
            };
            return newResult;
          },
        });
      }
    },

    async editProjectName(prj) {
      this.loadingUpdateProject = true;
      try {
        await this.$apollo.mutate({
          mutation: require("@/graphql/ProjectUpdate.gql"),
          variables: {
            name: this.newPrjName,
            id: prj.id,
          },
        });
      } catch (error) {
        console.error("fail uodate project", error);
      } finally {
        this.loadingUpdateProject = false;
      }
    },

    toggleEditName(index, project) {
      // console.log('this.editList', )
      let newIsEditVal = !this.editList[index];
      if (newIsEditVal) {
        this.newPrjName = project.name;
        this.isEditName = true;
      } else {
        this.newPrjName = "";
        this.isEditName = false;
      }
      this.$set(this.editList, index, newIsEditVal);
    },

    async deleteProject() {
      let project = this.prjToDelete;
      this.deleteCodename = project.codename;
      this.deleteLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: require("@/graphql/ProjectDelete.gql"),
          variables: {
            id: project.id,
          },
        });
        this.refetchProjectList();
      } catch (error) {
        console.error(
          "can not delete project with pk " + project.id + "\nError:\n",
          error
        );
      } finally {
        this.deleteLoading = false;
        this.deleteCodename = "";
        this.cancelDelete();
      }
    },

    createNewProject() {
      this.createProject = true;
    },
  },
};
</script>

<style lang="scss">
// .home-page {
//   // height: 100%;
//   &__header {
//     display: flex;
//     justify-content: space-between;
//   }
// }

.v-card__title {
  line-height: 1;
  color: black;
}

.edit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 4;
}
.delete {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 4;
}
.project-link {
  width: 100%;
  display: block;
}

.project {
  position: relative;
  &__edit-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    visibility: hidden;
    z-index: 4;
    transition: 0.3s;
  }
  &__delete-btn {
    position: absolute;
    top: 10px;
    right: 60px;
    opacity: 0;
    visibility: hidden;
    z-index: 4;
    transition: 0.3s;
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    &:after {
      position: absolute;
      content: "";
      top: 1px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  &__title {
    position: relative;
    z-index: 3;
    color: white;
    &-edit {
      margin: 20px 0 4px 0;
      // margin-right: 50px;
    }
  }
  &__create-date {
    position: relative;
    z-index: 3;
  }
  &:hover {
    .project__edit-btn {
      opacity: 1;
      visibility: visible;
    }
    .project__delete-btn {
      opacity: 1;
      visibility: visible;
    }
  }

  &__create-new {
    height: 500px;
  }
}
.edit-name-input-wite-text input {
  color: white !important;
}
.full-width {
  width: 100%;
}
</style>
